.form-check-input{

    fill: var(--white, #FFF);
filter: drop-shadow(0px 32px 56px #7841ED) 
drop-shadow(0px 24px 24px rgba(56, 147, 232, 0.25))
 drop-shadow(0px 16px 16px rgba(243, 116, 132, 0.40));
}

.imageDiv:hover img {
    opacity: 0.3;
}

imageDiv:hover {
    border: 1px solid #043985;
}


.imageDiv {
    position: relative;
}

.imageDiv:hover {
    border: 1px solid #043985;
}

.remove {
    right: -5%;
    top: -5%;
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: #EA0000;
    color: white;
    padding: 2px;
    border-radius: 50%;
    z-index: 9;
    text-align: center;
}

.upload-figure_width{
    max-width:240px ;
}

.new-btn{
    
      
        padding: 10px 20px;
        font-size: 20px;
}



@media(min-width:767px){

    .social{
        flex-direction: column;
    }

    
}

.user{
    position:absolute;
   top: 34px;
   left: 10px;
    
 
}

.labelsize .form-control{
    left: .5rem;
    padding-left: 2.5rem;
}


.eth{
    padding: 15px 20px 20px 20px;
   
}


.bag{
    position:absolute;
   top: 38px;
   left: 20px;
    
 
}


.infi{
    position:absolute;
    top: 36px;
    left: 20px;
    font-size: 18px;
}

.form-control,.form{
    height: 3rem;
    font-size: 14px;
}



  
    .delete {
        padding: 10px 14px 10px 14px;
    }

    .color-del{
        background-color: #2E294B;
    }


.add-bundle{
    padding: 10px 12px 10px 12px;
}

.add {
    font-size: 16px;
    
}


.upload-figure{
    width: 240px;
    height: 250px;
    
    img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
}


@media(max-width:768px){

     .upload-figure{
        width: 200px;
        height: 200px;
     }
   .upload-figure-two{
    width: 200px;
    height: 200px;
    
 }

 .btn-width{
    width: 200px;
 }
   
  
}

.btn-width{
    width: 200px;
}



.choose-img
{
    background-color: #2E294B;

}

.img-back{
  
        background-color: #160F3F;
        max-width: 250px;

}


.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;   
    cursor: inherit;
    display: block;
}

.photo{
    padding: 5.5rem;
}

.icon-edit{

        position: absolute;
        top: 36px;
        left: 22px;
    
}

@media(max-width:768px){

 
    
.labelsize{
    font-size: 10px;
}

}

.btn-upload{
    width: 200px;
}


::-webkit-calendar-picker-indicator{
    background-color:gray;
    padding: 3px;
    cursor: pointer;
    border-radius: 3px;
}

.calender {
    color: #fff;
}

.calender svg {
    width: 20px;
    height: 20px;
}
.input-date-area{
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
    z-index: 1;

}
.input-date-area span{
    position: relative;
    z-index: 99;
    font-size: 14px;
}
.input-date-area input{
    opacity: 0;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
}
.input-date-icon{
    position: absolute;
    right: 10px;
    font-size: 24px;
    top:10px;
    z-index: -1;
}


.req{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.react-datepicker-wrapper{
    width: 100%;
}
.date_text{
    padding: 0;
    font-size: 12px;
}