.start_text{
    font-size: 9px;
}
.edit_size{
    padding: 9px 6px;
    font-size: 14px;
    
   
}

 
