@media (max-width:768px){

    li .pagi {
      padding: 10px 10px 12px 12px;
  }
  }
  
  
    .pagi{
      padding: 14px 19px 14px 19px ;
    }
    