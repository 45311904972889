.page{
  color: white;
  background: var(--gradient, linear-gradient(49deg, #FF817D 0%, #8233FF 46.35%, #3857FD 63.02%, #22DEF1 100%));  
   
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    z-index: 1;
   
}
.bg_circle{position: relative;}
.bg_circle:before{
    content: "";
    position: absolute;
    background: linear-gradient(150deg, #35d2e2ab 0.04%, rgb(53 210 226 / 0%) 57.34%);
    opacity: 0.4;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    z-index: -1;
    margin: 0px auto;
    left: 0;
    right: 0;    
} 




.not-found{
    font-size:60px;
}
.home{
    height:80px;
    
}



.oops{
    font-size: 30px;
    font-weight: 400;
    
}
.parag {
    z-index: -1;
    text-shadow: 0px 0px 64px #5FB2FF;
    font-size: 198px;
    font-style: normal;
    font-weight: 400;
    line-height: 200px;
    letter-spacing: -16.76px;
    text-transform: uppercase;
    position: relative;
    top: 100px;
    background: -webkit-linear-gradient(rgb(255 255 255), rgb(255 255 255 / 0%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
    .main-img {
        position: relative;
    }
.error_page{
    padding:8rem 0 0;
    position: relative;
}
@media screen and (max-width:767px){
    .home_btn {
        text-align: center;
    }
    .parag {
        font-size: 116px;
        letter-spacing: 0;
        top:0;
    }
 
    .oops {
        font-size: 28px;
        text-align: center;
    }


   
    .main-img {
        position: relative;
        top: -65px;
    }
    .bg_circle:before {
        width: 360px;
        height: 360px;
        bottom: 70px;
       
        
      
        
    }
}