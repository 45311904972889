@import '../../assets/scss/responsive';

.terms-condition{
    text-align: center;
    padding-bottom: 10px;
    a{
        color:#fff;
        @media(max-width:$breakpoint-mobile){
            display: block;
        }
    }
}
@media(max-width:$breakpoint-mobile){
.footer-content{
    padding: 0px 20px;
    font-size: 16px;
}
}

.footer{
    bottom: 0;
}