.header-element{
    
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    background-color:#070123;
    margin: 0px auto;
    left: 0;
    right: 0;
    
    z-index: 999;
@media(max-width:767px){
    .navbar-brand{
        width:100px;
    }
}
}
.btn-gadient{
    background: linear-gradient(29deg, rgba(255,129,125,1) 0%, rgba(130,51,255,1) 37%, rgba(56,87,253,1) 73%, rgba(34,222,241,1) 100%);
    border: none;
    text-transform: uppercase;
}
.btn-header{
    padding: 10px 20px;
    border-radius: 10px;
}

.btn-logout{
    margin-left: auto;
    padding: 10px 20px;
    border-radius: 10px;
}




.custom-alert-overlay .react-confirm-alert-body{
    background: #1A0D58;
    color: #fff;
    text-align: center;
    font-family: inherit;
    box-shadow:  0px 0px 64px rgba(95, 178, 255, 0.35);
   
}
.custom-alert-overlay.react-confirm-alert-overlay{
   background: rgba(19, 16, 40, 0.8);
   z-index: 999;
   
}

.custom-alert-overlay .react-confirm-alert-body h1{
    font-size: 24px;
}
.custom-alert-overlay .react-confirm-alert-button-group{
    justify-content:center;
}
.custom-alert-overlay .react-confirm-alert-button-group button{
    justify-content:center;
    background: linear-gradient(29deg, rgba(255,129,125,1) 0%, rgba(130,51,255,1) 37%, rgba(56,87,253,1) 73%, rgba(34,222,241,1) 100%);
    border: none;
    text-transform: uppercase;
    padding: 10px 20px;
}