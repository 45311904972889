.colorback {
  background-color: #130D33;
}

.imgedit.img {

  padding: 8px 6px 6px 6px;

}

.border-color {
  border: 1px solid blue;
  border-radius: 14px;

}




.btn-group>.btn,
.btn-group-vertical>.btn {
  border-radius: 14px;
  background-color: transparent;
}

.imgedit {
  padding: 10px 2px 8px 2px;
  background: #1D144B;
  border-radius: 4px;
}

.tsize {
  font-size: 14px;
}


.right-text {
  display: flex;
  align-items: end;
}

.textorange {
  color: #EC8481;
}

.textellic {
  color: #752CE8;
}

.progress {
  width: 160px;
  height: 160px !important;
  margin: 30px;
  line-height: 150px;
  background: none;

  box-shadow: none;
  position: relative;

}

@media(max-width:768px) {

  .marginbottom {
    margin-top: 10px;
  }

  h5 {
    font-size: 18px;
    padding-top: 10px;
  }

  .progress {

    margin: 0px;

    padding-top: 10px;
  }


  .drop-btn {
    padding-top: 6px;
  }

  .prizeall {
    font-size: 14px;

  }

  .btngroup {
    padding-top: 8px;
  }

}







.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #4B4663;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;

}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 95px;
  border-bottom-right-radius: 95px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 95px;
  border-bottom-left-radius: 95px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: #28183A;
  font-size: 24px;
  color: #fff;
  line-height: 100px;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 10%;

}



.progress .progress-ellic {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: #1C0F45;
  font-size: 24px;
  color: #fff;
  line-height: 100px;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 10%;

}





.progress .progress-blue {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: #1C0F45;
  font-size: 24px;
  color: #fff;
  line-height: 100px;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 10%;
}


.progress .progress-ellic h6 {
  position: absolute;
  top: 55%;
  left: 10%;
  transform: translateX(12%);
}


.progress .progress-value h6 {


  position: absolute;
  top: 55%;
  left: 10%;
  transform: translateX(12%);

}


.progress .progress-blue h6 {
  position: absolute;
  top: 55%;
  left: 10%;
  transform: translateX(12%);

}





.progress.orange .progress-bar {
  border-color: #EC8481;


}

.progress.orange .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;

}

.progress.ellic .progress-bar {
  border-color: #752CE8;
}

.progress.ellic .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}

.progress.ellic .progress-left .progress-bar {
  animation: none;
}

.progress.blue .progress-bar {
  border-color: blue;


}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;

}











@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}


.box-edit {
  padding: 16px 14px 16px 14px;
  background-color: #752CE8;

}

.box {
  padding: 16px 14px 16px 14px;
  background-color: #3B57EE;
}

.box3 {
  padding: 16px 14px 16px 14px;
  background-color: #35D2E2;
}

.game {
  background-color: #3B57EE;
}


.games {
  padding-top: 80px;
  padding-bottom: 96px;
  
}
