.edit-profile{
    max-width: 400px;
    margin: 0px auto;
    text-align: left;
    input{
        padding: 30px;
        background: transparent;
        font-size: 18px;
        margin-top: 10px;
        color: #fff;
        &:focus,&:hover{
            background-color: transparent;
            color: #fff;
        }
       
    }
    .btn-default{
        font-size:36px;
    }
}
.file{
    width: 175px;
    height: 180px;
    border-radius: 20px;
   
    input{
        z-index: 1;
    position: absolute;
    font-size: 0;
    width: 27px;
    height: 27px;
    opacity: 0;
    right:0;
    top:0;
    cursor: pointer;
    }
    i{
        position:absolute;
        right:0;
        top:0;
    }
    &-btn{
        position: relative;
        top: 10px;
    right: 10px;
    }
  
}



@media (max-width:767px) {
    .email_text{
        font-size: 14px;
    }
    
}


.update-btn{

    max-width: 200px;
    font-size: 18px;
    box-shadow: none;
}



.color-light
{
    background-color: rgb(11, 11, 60);
}