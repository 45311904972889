
$color-white: #fff;
$color-green: #00FF00;
$color-blue: #1A0D58;
$color-dark-blue: #070123;
$color-gray: #A4A9C7;
$color-cyan:#35D2E2;
$color-light:#5FB2FF;
$color-skyblue:#752CE8;
$color-back:#120C32;
$colorback:#130D33;
$colorblue:#3B57EE;
$img-color:#160F3F;