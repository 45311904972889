
.btn-default.cheak:hover{
    background-color: transparent;
}


.edit_size{
    padding: 9px 6px;
    font-size: 14px;
    
   
}

.edit_like{
    padding: 4px 4px 4px 4px;
}

 
.border.border_like{
    border: 2px solid gray !important;
}