/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 5, 2023 */



@font-face {
    font-family: 'azonixregular';
    src: url('azonix-webfont.woff2') format('woff2'),
         url('azonix-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'digital-7_monomono';
    src: url('digital-7__mono_-webfont.woff2') format('woff2'),
         url('digital-7__mono_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}