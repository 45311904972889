@import '../../assets/scss/responsive';
.main-terms{
    padding-right: 20px;
}
.pt-banner{
    padding-top: 130px;
    @media(max-width:$breakpoint-mobile){
        padding-top: 18rem;
    }
}
html{
    scroll-padding-top: 110px;
    @media(max-width:$breakpoint-mobile){
        scroll-padding-top: 18rem;
    }
}
.terms{
    
    font-size: 60px;
    font-style: normal;  
    text-transform: uppercase;
    @media(max-width:$breakpoint-mobile){
        font-size: 30px;
    }

}

.policy-sidebar{
    position: sticky;
    top: 110px;
    @media(max-width:$breakpoint-mobile){
        position: fixed;
        top: -9px;
        width: 100%;
        background: #070123;
        padding-top: 10rem;
    }
}

.inner-term{
    font-size: 18px;
font-style: normal;
font-weight: 400;
@media(max-width:$breakpoint-mobile){
    font-size: 16px;
}
color: var(--gray, #A4A9C7);
}




.navi{
    color:#A4A9C7;
    font-size:16px;
    font-weight: 400px;
}

.privacy{
font-size: 60px;
text-transform: uppercase;
}



