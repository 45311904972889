
.hei-widt li{
    
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0px 6px;
}

@media(min-width:768px){
    .filter{
flex-direction: column;
    }
}
