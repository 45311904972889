.deny{
    background: var(--blue, #3B57EE);
}
.approve{
   
background: var(--lilac, #752CE8);
}



.modal-content{
    background:transparent;
}