



// @media(max-width:990px){
  

//   .body-element .ull li{
//    width: 65px;
//   }

//   .body-element .fontsize{
//     display: none;
//   }

//   .body-element .sidebar-space{
//     padding-left: 60px !important;
//   }
//   .body-element .img-edit{
  
//     padding: 8px 0px 8px 0px !important;
//   }


//   .body-element  .btn-dashboard {
       
//         padding: 6px 10px !important;
//         font-size: 18px;
//     }
  
// }

// .hide-btn{
//   top:87px;
//   position: fixed;
// }
.nav-dropbtn{
  .dropdown-toggle::after{
    position: absolute;
    right: 10px;
    top: 28px;

  }

}
.nav-dropdown{
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
  &-link{
    padding: 10px 10px;
    &:hover{
      background-color: rgba(0,0,0,0.3);
      
    }
  }
}

.main-inner{
  min-height: calc(100vh - 195px);
  padding-bottom: 60px;
}
@media(max-width:1024px)

{

    .ull li{
        width: 50px;
       }
     
       .fontsize{
         display: none;
       }
     

       .img-edit{
       
         padding: 4px 0px 2px 0px !important;
       }
     
     
         .btn-dashboard {
            
             padding: 4px 10px !important;
             font-size: 18px;
         }



}






.wrapper{
    background-color: #070123;
    width: 100%;
 
}
.sidebar-space{
    padding-left: 335px;
}

  .sidebar-area {
    left: 0px;
    height: calc(100vh - 140px);
    background-color: #070123;
    overflow: auto;
    padding-right: 10px;
}

.sidebar{
    max-width: 350px;
}


.active,.btn-dashboard{
    
        background-color:#752CE8;
    
      
    
}

.btn-dashboard:hover{
    background-color: #752CE8;
}




.btn-dash{
    padding: 2px;
    
}


.btn-dashboard{
    background: #120C32;
    padding: 16px 12px;
    font-size: 20px;
}

.img-edit{
    padding:12px 4px 12px 4px;
    background: #1D144B;
    border-radius: 4px;
}

.fontsize{
    font-size: 16px;
}
@media(min-width:767px){
.body-element ul li .fontsize{
  font-size: 0;
  padding:0px !important;
}
.body-element .sidebar-space{
padding-left: 130px;
}
}

@media (max-width:1024px){
  .body-element .sidebar-area {
    left: -100px;
    transition: 0.3s ease-in-out;
    
  }
  .sidebar-space{
    padding-left: 70px;
}
  .body-element .sidebar-space{
    padding-left: 10px;  
    }
}



@media(width:768px)
{
  .sidebar-space{
    padding-left: 64px;
}
  
}

@media(max-width:1024px){
.fontsize{
  font-size: 12px;
}


}
