.form-check-input{

    fill: var(--white, #FFF);
filter: drop-shadow(0px 32px 56px #7841ED) 
drop-shadow(0px 24px 24px rgba(56, 147, 232, 0.25))
 drop-shadow(0px 16px 16px rgba(243, 116, 132, 0.40));

}

#defaultCheck1{
  width: 16px;
  height: 16px;
  margin-top: 0px;
}



.size{
  font-size: 12px;
}

.border_grd{
  border: 3px solid var(--gradient, #235596);
}
.one1 {
  color: #3b83ee;
}

  .search-icon{
    position: absolute;
    left:5px;
    top:5px;
  }