

.table > :not(caption) > * > * {
    padding: 0.8rem 0.5rem;
  
    background-color: var(--bs-table-bg);
    color: white;
    background-color:transparent;

}


@media (max-width:768px)
{
    .subs{
        max-width: 280px;
    }
    
}