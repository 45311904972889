
.nav-pills .nav-link.active{
    background-color:#130D33 ;
    color: cyan;
    border-bottom: 4px solid cyan;
    border-radius: 0px;
  }
  
  .nav-pills .nav-link{
  color: white;
  @media(max-width:767px){
    font-size:12px;
  }
  }

  .active,.tabs{
    background-color: transparent;
  }